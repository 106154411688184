import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoadUserResolver } from './load-user.resolver';
import { CanActivateGuard } from './msal/guards/canactivated.guard';
import { WorkingGroupGuard } from './auth/working-group.guard';

const routes: Routes = [
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'maintenance', loadChildren: () => import('./maintenance-page/maintenance-page.module').then(m => m.MaintenancePageModule), resolve: { user: LoadUserResolver }, canActivate: [CanActivateGuard, MsalGuard, WorkingGroupGuard] },
  { path: '', loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule), resolve: { user: LoadUserResolver }, canActivate: [CanActivateGuard, MsalGuard, WorkingGroupGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
