/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRequestContractWorksheetCreateContractWorksheetPost } from '../fn/contract-worksheet/api-request-contract-worksheet-create-contract-worksheet-post';
import { ApiRequestContractWorksheetCreateContractWorksheetPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-create-contract-worksheet-post';
import { apiRequestContractWorksheetCreateDraftPost } from '../fn/contract-worksheet/api-request-contract-worksheet-create-draft-post';
import { ApiRequestContractWorksheetCreateDraftPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-create-draft-post';
import { apiRequestContractWorksheetExportItemAdminPost } from '../fn/contract-worksheet/api-request-contract-worksheet-export-item-admin-post';
import { ApiRequestContractWorksheetExportItemAdminPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-export-item-admin-post';
import { apiRequestContractWorksheetExportItemPost } from '../fn/contract-worksheet/api-request-contract-worksheet-export-item-post';
import { ApiRequestContractWorksheetExportItemPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-export-item-post';
import { apiRequestContractWorksheetGetGet } from '../fn/contract-worksheet/api-request-contract-worksheet-get-get';
import { ApiRequestContractWorksheetGetGet$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-get-get';
import { apiRequestContractWorksheetGetItemsReferenceDetailPost } from '../fn/contract-worksheet/api-request-contract-worksheet-get-items-reference-detail-post';
import { ApiRequestContractWorksheetGetItemsReferenceDetailPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-get-items-reference-detail-post';
import { apiRequestContractWorksheetGetListGet } from '../fn/contract-worksheet/api-request-contract-worksheet-get-list-get';
import { ApiRequestContractWorksheetGetListGet$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-get-list-get';
import { apiRequestContractWorksheetImportItemAdminPost } from '../fn/contract-worksheet/api-request-contract-worksheet-import-item-admin-post';
import { ApiRequestContractWorksheetImportItemAdminPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-import-item-admin-post';
import { apiRequestContractWorksheetImportItemPost } from '../fn/contract-worksheet/api-request-contract-worksheet-import-item-post';
import { ApiRequestContractWorksheetImportItemPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-import-item-post';
import { apiRequestContractWorksheetInitialByRefContractWorksheetIdGet } from '../fn/contract-worksheet/api-request-contract-worksheet-initial-by-ref-contract-worksheet-id-get';
import { ApiRequestContractWorksheetInitialByRefContractWorksheetIdGet$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-initial-by-ref-contract-worksheet-id-get';
import { apiRequestContractWorksheetInitialGet } from '../fn/contract-worksheet/api-request-contract-worksheet-initial-get';
import { ApiRequestContractWorksheetInitialGet$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-initial-get';
import { apiRequestContractWorksheetSearchForBuyerWorksheetGet } from '../fn/contract-worksheet/api-request-contract-worksheet-search-for-buyer-worksheet-get';
import { ApiRequestContractWorksheetSearchForBuyerWorksheetGet$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-search-for-buyer-worksheet-get';
import { apiRequestContractWorksheetSearchGet } from '../fn/contract-worksheet/api-request-contract-worksheet-search-get';
import { ApiRequestContractWorksheetSearchGet$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-search-get';
import { apiRequestContractWorksheetSearchRpfPost } from '../fn/contract-worksheet/api-request-contract-worksheet-search-rpf-post';
import { ApiRequestContractWorksheetSearchRpfPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-search-rpf-post';
import { apiRequestContractWorksheetSubmitManualContractWorksheetPost } from '../fn/contract-worksheet/api-request-contract-worksheet-submit-manual-contract-worksheet-post';
import { ApiRequestContractWorksheetSubmitManualContractWorksheetPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-submit-manual-contract-worksheet-post';
import { apiRequestContractWorksheetTestMailPost } from '../fn/contract-worksheet/api-request-contract-worksheet-test-mail-post';
import { ApiRequestContractWorksheetTestMailPost$Params } from '../fn/contract-worksheet/api-request-contract-worksheet-test-mail-post';
import { ContractWorksheetBwItem } from '../models/contract-worksheet-bw-item';
import { ContractWorksheetDetail } from '../models/contract-worksheet-detail';
import { ContractWorksheetItemModel } from '../models/contract-worksheet-item-model';
import { ContractWorksheetSearchRefItem } from '../models/contract-worksheet-search-ref-item';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';

@Injectable({ providedIn: 'root' })
export class ContractWorksheetAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestContractWorksheetGetGet()` */
  static readonly ApiRequestContractWorksheetGetGetPath = '/api/request/contract-worksheet/Get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetGetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetGetGet$Response(params?: ApiRequestContractWorksheetGetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractWorksheetDetail>> {
    return apiRequestContractWorksheetGetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetGetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetGetGet(params?: ApiRequestContractWorksheetGetGet$Params, context?: HttpContext): Observable<ContractWorksheetDetail> {
    return this.apiRequestContractWorksheetGetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractWorksheetDetail>): ContractWorksheetDetail => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetGetListGet()` */
  static readonly ApiRequestContractWorksheetGetListGetPath = '/api/request/contract-worksheet/GetList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetGetListGet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetGetListGet$Response(params?: ApiRequestContractWorksheetGetListGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetDetail>>> {
    return apiRequestContractWorksheetGetListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetGetListGet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetGetListGet(params?: ApiRequestContractWorksheetGetListGet$Params, context?: HttpContext): Observable<Array<ContractWorksheetDetail>> {
    return this.apiRequestContractWorksheetGetListGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetDetail>>): Array<ContractWorksheetDetail> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetInitialGet()` */
  static readonly ApiRequestContractWorksheetInitialGetPath = '/api/request/contract-worksheet/Initial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetInitialGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetInitialGet$Response(params?: ApiRequestContractWorksheetInitialGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractWorksheetDetail>> {
    return apiRequestContractWorksheetInitialGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetInitialGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetInitialGet(params?: ApiRequestContractWorksheetInitialGet$Params, context?: HttpContext): Observable<ContractWorksheetDetail> {
    return this.apiRequestContractWorksheetInitialGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractWorksheetDetail>): ContractWorksheetDetail => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetSearchGet()` */
  static readonly ApiRequestContractWorksheetSearchGetPath = '/api/request/contract-worksheet/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetSearchGet$Response(params?: ApiRequestContractWorksheetSearchGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetSearchRefItem>>> {
    return apiRequestContractWorksheetSearchGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetSearchGet(params?: ApiRequestContractWorksheetSearchGet$Params, context?: HttpContext): Observable<Array<ContractWorksheetSearchRefItem>> {
    return this.apiRequestContractWorksheetSearchGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetSearchRefItem>>): Array<ContractWorksheetSearchRefItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetSearchForBuyerWorksheetGet()` */
  static readonly ApiRequestContractWorksheetSearchForBuyerWorksheetGetPath = '/api/request/contract-worksheet/SearchForBuyerWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetSearchForBuyerWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetSearchForBuyerWorksheetGet$Response(params?: ApiRequestContractWorksheetSearchForBuyerWorksheetGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetBwItem>>> {
    return apiRequestContractWorksheetSearchForBuyerWorksheetGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetSearchForBuyerWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetSearchForBuyerWorksheetGet(params?: ApiRequestContractWorksheetSearchForBuyerWorksheetGet$Params, context?: HttpContext): Observable<Array<ContractWorksheetBwItem>> {
    return this.apiRequestContractWorksheetSearchForBuyerWorksheetGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetBwItem>>): Array<ContractWorksheetBwItem> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetCreateDraftPost()` */
  static readonly ApiRequestContractWorksheetCreateDraftPostPath = '/api/request/contract-worksheet/CreateDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetCreateDraftPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetCreateDraftPost$Response(params?: ApiRequestContractWorksheetCreateDraftPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestContractWorksheetCreateDraftPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetCreateDraftPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetCreateDraftPost(params?: ApiRequestContractWorksheetCreateDraftPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestContractWorksheetCreateDraftPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetSubmitManualContractWorksheetPost()` */
  static readonly ApiRequestContractWorksheetSubmitManualContractWorksheetPostPath = '/api/request/contract-worksheet/SubmitManualContractWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetSubmitManualContractWorksheetPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetSubmitManualContractWorksheetPost$Response(params?: ApiRequestContractWorksheetSubmitManualContractWorksheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractWorksheetDetail>> {
    return apiRequestContractWorksheetSubmitManualContractWorksheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetSubmitManualContractWorksheetPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetSubmitManualContractWorksheetPost(params?: ApiRequestContractWorksheetSubmitManualContractWorksheetPost$Params, context?: HttpContext): Observable<ContractWorksheetDetail> {
    return this.apiRequestContractWorksheetSubmitManualContractWorksheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractWorksheetDetail>): ContractWorksheetDetail => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetInitialByRefContractWorksheetIdGet()` */
  static readonly ApiRequestContractWorksheetInitialByRefContractWorksheetIdGetPath = '/api/request/contract-worksheet/InitialByRefContractWorksheetId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetInitialByRefContractWorksheetIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetInitialByRefContractWorksheetIdGet$Response(params?: ApiRequestContractWorksheetInitialByRefContractWorksheetIdGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ContractWorksheetDetail>> {
    return apiRequestContractWorksheetInitialByRefContractWorksheetIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetInitialByRefContractWorksheetIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetInitialByRefContractWorksheetIdGet(params?: ApiRequestContractWorksheetInitialByRefContractWorksheetIdGet$Params, context?: HttpContext): Observable<ContractWorksheetDetail> {
    return this.apiRequestContractWorksheetInitialByRefContractWorksheetIdGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ContractWorksheetDetail>): ContractWorksheetDetail => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetSearchRpfPost()` */
  static readonly ApiRequestContractWorksheetSearchRpfPostPath = '/api/request/contract-worksheet/SearchRpf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetSearchRpfPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetSearchRpfPost$Response(params?: ApiRequestContractWorksheetSearchRpfPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>> {
    return apiRequestContractWorksheetSearchRpfPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetSearchRpfPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetSearchRpfPost(params?: ApiRequestContractWorksheetSearchRpfPost$Params, context?: HttpContext): Observable<Array<ContractWorksheetItemModel>> {
    return this.apiRequestContractWorksheetSearchRpfPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>): Array<ContractWorksheetItemModel> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetExportItemPost()` */
  static readonly ApiRequestContractWorksheetExportItemPostPath = '/api/request/contract-worksheet/ExportItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetExportItemPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetExportItemPost$Response(params?: ApiRequestContractWorksheetExportItemPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestContractWorksheetExportItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetExportItemPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetExportItemPost(params?: ApiRequestContractWorksheetExportItemPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestContractWorksheetExportItemPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetExportItemAdminPost()` */
  static readonly ApiRequestContractWorksheetExportItemAdminPostPath = '/api/request/contract-worksheet/ExportItemAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetExportItemAdminPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetExportItemAdminPost$Response(params?: ApiRequestContractWorksheetExportItemAdminPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<ExportExcelFileResponse>> {
    return apiRequestContractWorksheetExportItemAdminPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetExportItemAdminPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetExportItemAdminPost(params?: ApiRequestContractWorksheetExportItemAdminPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiRequestContractWorksheetExportItemAdminPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetImportItemPost()` */
  static readonly ApiRequestContractWorksheetImportItemPostPath = '/api/request/contract-worksheet/ImportItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetImportItemPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetImportItemPost$Response(params?: ApiRequestContractWorksheetImportItemPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>> {
    return apiRequestContractWorksheetImportItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetImportItemPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetImportItemPost(params?: ApiRequestContractWorksheetImportItemPost$Params, context?: HttpContext): Observable<Array<ContractWorksheetItemModel>> {
    return this.apiRequestContractWorksheetImportItemPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>): Array<ContractWorksheetItemModel> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetImportItemAdminPost()` */
  static readonly ApiRequestContractWorksheetImportItemAdminPostPath = '/api/request/contract-worksheet/ImportItemAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetImportItemAdminPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetImportItemAdminPost$Response(params?: ApiRequestContractWorksheetImportItemAdminPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>> {
    return apiRequestContractWorksheetImportItemAdminPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetImportItemAdminPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestContractWorksheetImportItemAdminPost(params?: ApiRequestContractWorksheetImportItemAdminPost$Params, context?: HttpContext): Observable<Array<ContractWorksheetItemModel>> {
    return this.apiRequestContractWorksheetImportItemAdminPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>): Array<ContractWorksheetItemModel> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetGetItemsReferenceDetailPost()` */
  static readonly ApiRequestContractWorksheetGetItemsReferenceDetailPostPath = '/api/request/contract-worksheet/GetItemsReferenceDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetGetItemsReferenceDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetGetItemsReferenceDetailPost$Response(params?: ApiRequestContractWorksheetGetItemsReferenceDetailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>> {
    return apiRequestContractWorksheetGetItemsReferenceDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetGetItemsReferenceDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetGetItemsReferenceDetailPost(params?: ApiRequestContractWorksheetGetItemsReferenceDetailPost$Params, context?: HttpContext): Observable<Array<ContractWorksheetItemModel>> {
    return this.apiRequestContractWorksheetGetItemsReferenceDetailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<ContractWorksheetItemModel>>): Array<ContractWorksheetItemModel> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetCreateContractWorksheetPost()` */
  static readonly ApiRequestContractWorksheetCreateContractWorksheetPostPath = '/api/request/contract-worksheet/CreateContractWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetCreateContractWorksheetPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetCreateContractWorksheetPost$Response(params?: ApiRequestContractWorksheetCreateContractWorksheetPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<string>>> {
    return apiRequestContractWorksheetCreateContractWorksheetPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetCreateContractWorksheetPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetCreateContractWorksheetPost(params?: ApiRequestContractWorksheetCreateContractWorksheetPost$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiRequestContractWorksheetCreateContractWorksheetPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiRequestContractWorksheetTestMailPost()` */
  static readonly ApiRequestContractWorksheetTestMailPostPath = '/api/request/contract-worksheet/testMail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestContractWorksheetTestMailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetTestMailPost$Response(params?: ApiRequestContractWorksheetTestMailPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRequestContractWorksheetTestMailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestContractWorksheetTestMailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiRequestContractWorksheetTestMailPost(params?: ApiRequestContractWorksheetTestMailPost$Params, context?: HttpContext): Observable<void> {
    return this.apiRequestContractWorksheetTestMailPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
