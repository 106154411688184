/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AzureApiConfiguration, AzureApiConfigurationParams } from './azure-api-configuration';

import { AccountTestApiAzureApiService } from './services/account-test-api-azure-api.service';
import { ActivityLogAzureApiService } from './services/activity-log-azure-api.service';
import { AdminAzureApiService } from './services/admin-azure-api.service';
import { AmazonS3AzureApiService } from './services/amazon-s-3-azure-api.service';
import { AuctionWorksheetAzureApiService } from './services/auction-worksheet-azure-api.service';
import { AuctionWorksheetTaskListAzureApiService } from './services/auction-worksheet-task-list-azure-api.service';
import { BuyerWorksheetAzureApiService } from './services/buyer-worksheet-azure-api.service';
import { BuyerWorksheetExportAzureApiService } from './services/buyer-worksheet-export-azure-api.service';
import { CalculationAzureApiService } from './services/calculation-azure-api.service';
import { ClaimComplaintInterfaceAzureApiService } from './services/claim-complaint-interface-azure-api.service';
import { CompanyAzureApiService } from './services/company-azure-api.service';
import { ConfAzureApiService } from './services/conf-azure-api.service';
import { ContractAmendmentAzureApiService } from './services/contract-amendment-azure-api.service';
import { ContractAmendmentTaskListAzureApiService } from './services/contract-amendment-task-list-azure-api.service';
import { ContractSearchingTaskListAzureApiService } from './services/contract-searching-task-list-azure-api.service';
import { ContractWorksheetAzureApiService } from './services/contract-worksheet-azure-api.service';
import { ContractWorksheetExportAzureApiService } from './services/contract-worksheet-export-azure-api.service';
import { ContractWorksheetTaskListAzureApiService } from './services/contract-worksheet-task-list-azure-api.service';
import { ContractWorkstationAzureApiService } from './services/contract-workstation-azure-api.service';
import { DailyInfoItemAzureApiService } from './services/daily-info-item-azure-api.service';
import { DashboardAzureApiService } from './services/dashboard-azure-api.service';
import { DbTestAzureApiService } from './services/db-test-azure-api.service';
import { EmailNotificationLogAzureApiService } from './services/email-notification-log-azure-api.service';
import { EmailSendingAzureApiService } from './services/email-sending-azure-api.service';
import { EmployeeAzureApiService } from './services/employee-azure-api.service';
import { FileAzureApiService } from './services/file-azure-api.service';
import { FileApiAzureApiService } from './services/file-api-azure-api.service';
import { FileConversionAzureApiService } from './services/file-conversion-azure-api.service';
import { GcCallAzureApiService } from './services/gc-call-azure-api.service';
import { HomeContentAzureApiService } from './services/home-content-azure-api.service';
import { ICustomsInterfaceAzureApiService } from './services/i-customs-interface-azure-api.service';
import { JobAzureApiService } from './services/job-azure-api.service';
import { K2PrpoInterfaceAzureApiService } from './services/k-2-prpo-interface-azure-api.service';
import { MasterAuctionBiddingAgreementAzureApiService } from './services/master-auction-bidding-agreement-azure-api.service';
import { MasterAuctionEmailTemplateAzureApiService } from './services/master-auction-email-template-azure-api.service';
import { MasterConditionValueTypeAzureApiService } from './services/master-condition-value-type-azure-api.service';
import { MasterContractEmailTemplateAzureApiService } from './services/master-contract-email-template-azure-api.service';
import { MasterContractTermConditionTemplateAzureApiService } from './services/master-contract-term-condition-template-azure-api.service';
import { MasterCountryAzureApiService } from './services/master-country-azure-api.service';
import { MasterCsmApproveAzureApiService } from './services/master-csm-approve-azure-api.service';
import { MasterCurrencyAzureApiService } from './services/master-currency-azure-api.service';
import { MasterEmailTemplateAzureApiService } from './services/master-email-template-azure-api.service';
import { MasterExchangeRateAzureApiService } from './services/master-exchange-rate-azure-api.service';
import { MasterFreightModeAzureApiService } from './services/master-freight-mode-azure-api.service';
import { MasterFtaAzureApiService } from './services/master-fta-azure-api.service';
import { MasterGreenProcurementAzureApiService } from './services/master-green-procurement-azure-api.service';
import { MasterIncotermAzureApiService } from './services/master-incoterm-azure-api.service';
import { MasterKeywordAzureApiService } from './services/master-keyword-azure-api.service';
import { MasterPdpaAzureApiService } from './services/master-pdpa-azure-api.service';
import { MasterPlantAzureApiService } from './services/master-plant-azure-api.service';
import { MasterPoDocTypeAzureApiService } from './services/master-po-doc-type-azure-api.service';
import { MasterPortAzureApiService } from './services/master-port-azure-api.service';
import { MasterPurGroupAzureApiService } from './services/master-pur-group-azure-api.service';
import { MasterPurGroupMemberAzureApiService } from './services/master-pur-group-member-azure-api.service';
import { MasterRestrictedGoodAzureApiService } from './services/master-restricted-good-azure-api.service';
import { MasterRoleResponsibleAzureApiService } from './services/master-role-responsible-azure-api.service';
import { MasterSimpleValueAzureApiService } from './services/master-simple-value-azure-api.service';
import { MasterSourcingTeamAzureApiService } from './services/master-sourcing-team-azure-api.service';
import { MasterSynergyCostSavingAzureApiService } from './services/master-synergy-cost-saving-azure-api.service';
import { MasterTemplateFileAzureApiService } from './services/master-template-file-azure-api.service';
import { MasterTermConditionTemplateAzureApiService } from './services/master-term-condition-template-azure-api.service';
import { MasterUnitAzureApiService } from './services/master-unit-azure-api.service';
import { MasterVendorAzureApiService } from './services/master-vendor-azure-api.service';
import { MasterVendorMatchingTypeAzureApiService } from './services/master-vendor-matching-type-azure-api.service';
import { MasterVeNusCategoryAndSeverityAzureApiService } from './services/master-ve-nus-category-and-severity-azure-api.service';
import { MeAzureApiService } from './services/me-azure-api.service';
import { PrCognitiveAzureApiService } from './services/pr-cognitive-azure-api.service';
import { ReferenceLinkAzureApiService } from './services/reference-link-azure-api.service';
import { ReqDataAzureApiService } from './services/req-data-azure-api.service';
import { RfqRequestAzureApiService } from './services/rfq-request-azure-api.service';
import { RfqTaskInfoAzureApiService } from './services/rfq-task-info-azure-api.service';
import { RpfRequestAzureApiService } from './services/rpf-request-azure-api.service';
import { SapInterfacePoCreationAzureApiService } from './services/sap-interface-po-creation-azure-api.service';
import { SapInterfacePoCreationResponseAzureApiService } from './services/sap-interface-po-creation-response-azure-api.service';
import { ServerTestAzureApiService } from './services/server-test-azure-api.service';
import { SeverEventAzureApiService } from './services/sever-event-azure-api.service';
import { SynergyRfqAzureApiService } from './services/synergy-rfq-azure-api.service';
import { SystemLogAzureApiService } from './services/system-log-azure-api.service';
import { TaskListAzureApiService } from './services/task-list-azure-api.service';
import { VeNusInterfaceAzureApiService } from './services/ve-nus-interface-azure-api.service';
import { WeatherForecastAzureApiService } from './services/weather-forecast-azure-api.service';
import { WorkGroupAzureApiService } from './services/work-group-azure-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountTestApiAzureApiService,
    ActivityLogAzureApiService,
    AdminAzureApiService,
    AmazonS3AzureApiService,
    AuctionWorksheetAzureApiService,
    AuctionWorksheetTaskListAzureApiService,
    BuyerWorksheetAzureApiService,
    BuyerWorksheetExportAzureApiService,
    CalculationAzureApiService,
    ClaimComplaintInterfaceAzureApiService,
    CompanyAzureApiService,
    ConfAzureApiService,
    ContractAmendmentAzureApiService,
    ContractAmendmentTaskListAzureApiService,
    ContractSearchingTaskListAzureApiService,
    ContractWorksheetAzureApiService,
    ContractWorksheetExportAzureApiService,
    ContractWorksheetTaskListAzureApiService,
    ContractWorkstationAzureApiService,
    DailyInfoItemAzureApiService,
    DashboardAzureApiService,
    DbTestAzureApiService,
    EmailNotificationLogAzureApiService,
    EmailSendingAzureApiService,
    EmployeeAzureApiService,
    FileAzureApiService,
    FileApiAzureApiService,
    FileConversionAzureApiService,
    GcCallAzureApiService,
    HomeContentAzureApiService,
    ICustomsInterfaceAzureApiService,
    JobAzureApiService,
    K2PrpoInterfaceAzureApiService,
    MasterAuctionBiddingAgreementAzureApiService,
    MasterAuctionEmailTemplateAzureApiService,
    MasterConditionValueTypeAzureApiService,
    MasterContractEmailTemplateAzureApiService,
    MasterContractTermConditionTemplateAzureApiService,
    MasterCountryAzureApiService,
    MasterCsmApproveAzureApiService,
    MasterCurrencyAzureApiService,
    MasterEmailTemplateAzureApiService,
    MasterExchangeRateAzureApiService,
    MasterFreightModeAzureApiService,
    MasterFtaAzureApiService,
    MasterGreenProcurementAzureApiService,
    MasterIncotermAzureApiService,
    MasterKeywordAzureApiService,
    MasterPdpaAzureApiService,
    MasterPlantAzureApiService,
    MasterPoDocTypeAzureApiService,
    MasterPortAzureApiService,
    MasterPurGroupAzureApiService,
    MasterPurGroupMemberAzureApiService,
    MasterRestrictedGoodAzureApiService,
    MasterRoleResponsibleAzureApiService,
    MasterSimpleValueAzureApiService,
    MasterSourcingTeamAzureApiService,
    MasterSynergyCostSavingAzureApiService,
    MasterTemplateFileAzureApiService,
    MasterTermConditionTemplateAzureApiService,
    MasterUnitAzureApiService,
    MasterVendorAzureApiService,
    MasterVendorMatchingTypeAzureApiService,
    MasterVeNusCategoryAndSeverityAzureApiService,
    MeAzureApiService,
    PrCognitiveAzureApiService,
    ReferenceLinkAzureApiService,
    ReqDataAzureApiService,
    RfqRequestAzureApiService,
    RfqTaskInfoAzureApiService,
    RpfRequestAzureApiService,
    SapInterfacePoCreationAzureApiService,
    SapInterfacePoCreationResponseAzureApiService,
    ServerTestAzureApiService,
    SeverEventAzureApiService,
    SynergyRfqAzureApiService,
    SystemLogAzureApiService,
    TaskListAzureApiService,
    VeNusInterfaceAzureApiService,
    WeatherForecastAzureApiService,
    WorkGroupAzureApiService,
    AzureApiConfiguration
  ],
})
export class AzureApiModule {
  static forRoot(params: AzureApiConfigurationParams): ModuleWithProviders<AzureApiModule> {
    return {
      ngModule: AzureApiModule,
      providers: [
        {
          provide: AzureApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: AzureApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('AzureApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
