/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiRefItemGet } from '../fn/reference-link/api-ref-item-get';
import { ApiRefItemGet$Params } from '../fn/reference-link/api-ref-item-get';

@Injectable({ providedIn: 'root' })
export class ReferenceLinkAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRefItemGet()` */
  static readonly ApiRefItemGetPath = '/api/ref-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRefItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRefItemGet$Response(params?: ApiRefItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiRefItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRefItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRefItemGet(params?: ApiRefItemGet$Params, context?: HttpContext): Observable<void> {
    return this.apiRefItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
