/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiInterfaceK2PrpoUpdateCostSavingGet$Params {
  poNumber?: string;
  bwId?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiInterfaceK2PrpoUpdateCostSavingGet(http: HttpClient, rootUrl: string, params?: ApiInterfaceK2PrpoUpdateCostSavingGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiInterfaceK2PrpoUpdateCostSavingGet.PATH, 'get');
  if (params) {
    rb.query('poNumber', params.poNumber, {});
    rb.query('bwId', params.bwId, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as AzureApiStrictHttpResponse<void>;
    })
  );
}

apiInterfaceK2PrpoUpdateCostSavingGet.PATH = '/api/interface/K2PRPO/UpdateCostSaving';
