/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiInterfaceK2PrpoAutoSubmitPoGet } from '../fn/k-2-prpo-interface/api-interface-k-2-prpo-auto-submit-po-get';
import { ApiInterfaceK2PrpoAutoSubmitPoGet$Params } from '../fn/k-2-prpo-interface/api-interface-k-2-prpo-auto-submit-po-get';
import { apiInterfaceK2PrpoUpdateCostSavingGet } from '../fn/k-2-prpo-interface/api-interface-k-2-prpo-update-cost-saving-get';
import { ApiInterfaceK2PrpoUpdateCostSavingGet$Params } from '../fn/k-2-prpo-interface/api-interface-k-2-prpo-update-cost-saving-get';

@Injectable({ providedIn: 'root' })
export class K2PrpoInterfaceAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInterfaceK2PrpoAutoSubmitPoGet()` */
  static readonly ApiInterfaceK2PrpoAutoSubmitPoGetPath = '/api/interface/K2PRPO/AutoSubmitPO';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceK2PrpoAutoSubmitPoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceK2PrpoAutoSubmitPoGet$Response(params?: ApiInterfaceK2PrpoAutoSubmitPoGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiInterfaceK2PrpoAutoSubmitPoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceK2PrpoAutoSubmitPoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceK2PrpoAutoSubmitPoGet(params?: ApiInterfaceK2PrpoAutoSubmitPoGet$Params, context?: HttpContext): Observable<void> {
    return this.apiInterfaceK2PrpoAutoSubmitPoGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiInterfaceK2PrpoUpdateCostSavingGet()` */
  static readonly ApiInterfaceK2PrpoUpdateCostSavingGetPath = '/api/interface/K2PRPO/UpdateCostSaving';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterfaceK2PrpoUpdateCostSavingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceK2PrpoUpdateCostSavingGet$Response(params?: ApiInterfaceK2PrpoUpdateCostSavingGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiInterfaceK2PrpoUpdateCostSavingGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInterfaceK2PrpoUpdateCostSavingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterfaceK2PrpoUpdateCostSavingGet(params?: ApiInterfaceK2PrpoUpdateCostSavingGet$Params, context?: HttpContext): Observable<void> {
    return this.apiInterfaceK2PrpoUpdateCostSavingGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
